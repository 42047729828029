import React from "react";
import styled from "styled-components";

const ContainerStyle = styled.div.attrs(() => ({ id: "test-body" }))`
  width: 8.5in;
  height: 11in;
  border: 0.1in solid black;
`;

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Adjust the number of columns */
  gap: 0.1in; /* Adjust the gap between grid items */
  padding: 0.1in;
  box-sizing: border-box;
`;

const LineupTable = styled.div`
  border: 3px solid black;
  height: 5in;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1in;
`;

const AvailablePlayersTable = styled.div`
  border: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5in;
  margin-top: 0.2in;
`;

const AvailablePitchersTable = styled.div`
  border: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5in;
  margin-top: 0.2in;
`;

const TestContainer = () => {
  return (
    <ContainerStyle>
      <GridStyle>
        <LineupTable>Home Lineup</LineupTable>
        <LineupTable>Away Lineup</LineupTable>
        <AvailablePlayersTable>Home Available Players</AvailablePlayersTable>
        <AvailablePlayersTable>Home Available Players</AvailablePlayersTable>
        <AvailablePitchersTable>Away Available Players</AvailablePitchersTable>
        <AvailablePitchersTable>Away Available Pitchers</AvailablePitchersTable>
      </GridStyle>
    </ContainerStyle>
  );
};

export default TestContainer;
