import React from "react";
import LineupCardNavigation from "./components/navigation/LineupCardNavigation";
import LineupCardAlerts from "./components/alerts/LineupcardAlerts";
import { NavigationContainer } from "best-common-react";
import { Route, Switch } from "react-router-dom";
import { RouteConstants } from "./constants/RouteConstants";
import { LoginCallback, SecureRoute } from "@okta/okta-react";
import GamesContainer from "./containers/GamesContainer";
import CustomCardsContainer from "./containers/CustomCardsContainer";
import EditCustomCardContainer from "./containers/EditCustomCardContainer";
import LogoContainer from "./containers/LogoContainer";
import RosterSettingsContainer from "./containers/RosterSettingsContainer";
import { useAuth } from "./contexts/AuthContext";
import LineupSettingsContainer from "./containers/LineupSettingsContainer";
import TestContainer from "./containers/TestContainer";

const AuthenticatedApp = () => {
  const {
    state: { loggedIn }
  } = useAuth();
  return (
    <div className="lc-body">
      <LineupCardAlerts />
      <NavigationContainer vertical={false} loggedIn={loggedIn} displayEnvIndicator={false}>
        <LineupCardNavigation />
        <Switch>
          <Route path={RouteConstants.LOGIN_CALLBACK} exact component={LoginCallback} />
          <SecureRoute exact path={RouteConstants.BASE} component={GamesContainer} />
          <SecureRoute exact path={RouteConstants.GAMES} component={GamesContainer} />
          <SecureRoute exact path={RouteConstants.CUSTOM_CARDS} component={CustomCardsContainer} />
          <SecureRoute exact path={RouteConstants.EDIT_CUSTOM_CARD} component={EditCustomCardContainer} />
          <SecureRoute exact path={RouteConstants.LOGO} component={LogoContainer} />
          <SecureRoute exact path={RouteConstants.ROSTER_SETTINGS} component={RosterSettingsContainer} />
          <SecureRoute exact path={RouteConstants.DEFAULT_LINEUPS} component={LineupSettingsContainer} />
          <SecureRoute exact path={RouteConstants.TEST} component={TestContainer} />
        </Switch>
      </NavigationContainer>
    </div>
  );
};

export default AuthenticatedApp;
